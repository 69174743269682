import React, { Component } from 'react';
import { connect } from 'react-redux';
//import {matchRoutes} from "react-router-config";
import {API} from "../config";
import {removeUserInfo, setUserInfo} from "../store/actions";
import {translate} from "react-i18next";

export default function (ComposedComponent) {
  class Authorized extends Component {
    state = {
      isLoading: true,
    };

    componentDidMount() {
      this.checkAuth();
      /*if (!this.props.authenticated) {
        console.log('fjkdshfkdsa');
        window.location.href = '/login';
      } else {*/

      //}*/
    }

    isEmpty(str){
      return (!str || 0 === str.length);
    }

    checkAuth()
    {
      const contraller = this;
      if( (typeof this.props.user._account === 'undefined') && !this.isEmpty(window.localStorage.getItem('feathers-jwt') && this.props.location.pathname !== '/login')){
        API.authenticate({
          strategy: 'jwt',
          accessToken: window.localStorage.getItem('feathers-jwt')
        }).then((response)=>{
          //API.verifyJWT(response.accessToken).then((data)=>{
            API.service('investors').get(response.investor._id).then((i)=>{
              let lng = (i._language === 'CN') ? 'zh_CN' : i._language;
              this.props.i18n.changeLanguage(lng);

              contraller.props.setUserInfo(i);
              contraller.setState({ isLoading: false });
            });
          //})
        }).catch((e)=>{
          console.log("Not Authenticated z :",e);
          this.props.history.push({
            pathname: '/login'
          });
        })
      } else if(typeof this.props.user === 'undefined')  {
        this.props.history.push({
          pathname: '/login'
        });
      } else if(this.isEmpty(window.localStorage.getItem('feathers-jwt') && this.props.location.pathname !== '/login')) {
        this.props.history.push({
          pathname: '/login'
        });
      } else {
        contraller.setState({ isLoading: false });
      }
    }

    render() {
      const { isLoading } = this.state;

      if (isLoading) return null;

      return <ComposedComponent />
    }
  }

  const mapStateToProps = state => ({
    authenticated: state.user.authenticated,
    user: state.user.userInfo,
  });

  const mapDispatchToProps = {
    setUserInfo,
    removeUserInfo,
  };

  return connect(mapStateToProps,mapDispatchToProps)(translate('translations')(Authorized))
}
