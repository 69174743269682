import { combineReducers } from 'redux';
//import { persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';

import fuse from './fuse';
import company from './company';
import user from './user';

//const config = {
//  key: 'user',
  //storage,
//};

const createReducer = (asyncReducers) =>
  combineReducers({
    fuse,
    company,
    user, //: persistReducer(config, user),
    ...asyncReducers
  });

export default createReducer;
