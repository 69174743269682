import { FuseLoadable } from '@fuse';

export const Login2PageConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        scroll: 'content',
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    },
    theme: {
      main: 'defaultDark',
      navbar: 'mainThemeDark',
      toolbar: 'mainThemeLight',
      footer: 'mainThemeDark'
    }
  },
  routes: [
    {
      path: '/login',
      component: FuseLoadable({
        loader: () => import('./Login2Page')
      })
    },
    {
      path: '/login_new',
      component: FuseLoadable({
        loader: () => import('./Login2PageNew')
      })
    },
    {
      path: '/diagnostics',
      component: FuseLoadable({
        loader: () => import('./Diagnostics')
      })
    },
    {
      path: '/tos',
      component: FuseLoadable({
        loader: () => import('./Tos')
      })
    },
  ]
};
