import { FuseLoadable } from '@fuse';
import Authorize from '../../Authorize';

export const LanguagesConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/languages',
      component: Authorize(FuseLoadable({
        loader: () => import('./Languages')
      }))
    },
  ]
};
