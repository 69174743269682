
// User Actions
export const SET_USER_INFO = '[USER] SET USER INFO';
export const LOG_OUT = '[USER] LOGOUT';
export const REMOVE_USER_INFO = '[USER] REMOVE USER INFO';
export const SET_INBOX_ITEM_COUNT = '[USER] SET INBOX ITEM COUNT';

const _setInboxItemCount = (payload) => ({
  type: SET_INBOX_ITEM_COUNT,
  payload
});
const _setUserInfo = (payload) => ({
  type: SET_USER_INFO,
  payload
});
const _logOut = () => ({
  type: LOG_OUT,
});

export const logOut = () => (dispatch) => {
  dispatch(_logOut());
  window.location.replace('/login');
};
export const setUserInfo = (payload) => (dispatch) => {
  dispatch(_setUserInfo(payload));
  return true;
};
export const removeUserInfo = () => (dispatch) => {
  dispatch(_logOut());
};
export const setInboxItemCount = (payload) => (dispatch) => {
  dispatch(_setInboxItemCount(payload));
  return true;
};
