import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//let redirect = false;

class FuseAuthorization extends Component {

  /*constructor(props) {
    super(props);
    //this.checkAuth();
  }*/

  render() {
    const { children } = this.props;

    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default withRouter(FuseAuthorization);
