import { fuseDark } from '@fuse/fuse-colors';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';

export const fuseThemesConfig = {
  default: {
    palette: {
      type: 'light',
      last_price:{
        background:'#005e8f',
        text:'white'
      },
      watchListSelected:{
        background:'#e19f13',
        text:'black'
      },
      tradeAlertGreen:{
        background: 'rgba(40,50,50,0.6)',
        text: 'white',
      },
      tradeAlertRed:{
        background:'rgba(255,0,0,0.6)',
        text:'white',
      },
      tradeAlertOrange:{
        background:'rgba(255,176,68,0.6)',
        text:'black'
      },
      text:{
        invert:'white',
        color:'rgba(0,0,0,0.87)'
      },
      primary: fuseDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700]
      },
      background:{
        default:'white',
        offBase:'rgba(227, 230, 234, 0.5)',
        darker:'rgba(227, 230, 234, 0.8)',
        darkest:'rgba(227, 230, 234, 1)',
      },
      error: red,
      watchListSelectedTab:'#e19f13',
      embededSelectedTab:'#e19f13',
      selectedTab:'#3c4250',
      pageHeaderSelectedTab:'#005e8f',
      nonSelectedTab:'#005e8f',
    },
    status: {
      danger: 'orange'
    },


  },
  defaultDark: {
    palette: {
      type: 'dark',
      last_price:{
        background:'#2c1d22',
        text:'white'
      },
      watchListSelected:{
        background:'#3c4250',
        text:'white'
      },
      tradeAlertGreen:{
        background: 'rgba(34,175,35,0.6)',
        text: 'white',
      },
      tradeAlertRed:{
        background:'rgba(255,0,0,0.6)',
        text:'white',
      },
      tradeAlertOrange:{
        background:'rgba(255,176,68,0.6)',
        text:'black'
      },
      primary: fuseDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700]
      },
      text:{
        invert:'black',
        color:'white'
      },
      background:{
        default:'#23272f',
        offBase:'#23272f',
        darker:'#25242C',
        darkest:'#2e323a',
      },
      error: red,
      watchListSelectedTab:'#f59331',
      embededSelectedTab:'#3c4250',
      pageHeaderSelectedTab:'#3c4250',
      selectedTab:'#b15415',
      nonSelectedTab:'#005e8f',
    },
    status: {
      danger: 'orange'
    },
  }
};
