import React from 'react';
import { withStyles, Hidden } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { FuseNavigation, FuseLayouts } from '@fuse';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    '& .user': {
      '& .username, & .email': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut
        })
      }
    },
    height: '100%'
  },
  avatar: {
    width: 72,
    height: 72,
    position: 'absolute',
    top: 92,
    padding: 8,
    background: theme.palette.background.default,
    boxSizing: 'content-box',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > img': {
      borderRadius: '50%'
    }
  }
});


function MainNavbar({ classes, navigation, layoutStyle }) {


  const navigationLayout = FuseLayouts[layoutStyle].type;
  return (
    <div className={classes.root}>
      {navigationLayout === 'vertical' ? (
        <React.Fragment>
          <FuseNavigation navigation={navigation} layout={navigationLayout} />
        </React.Fragment>
      ) : (
          <React.Fragment>
            <Hidden lgUp>
            </Hidden>
            <FuseNavigation navigation={navigation} layout={navigationLayout} />
          </React.Fragment>
        )}

    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ fuse, auth }) {
  return {
    navigation: fuse.navigation,
    layoutStyle: fuse.settings.current.layout.style,
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavbar)));
