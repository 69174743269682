import React, { Component } from 'react';
import styled from 'styled-components';
import { fuseDark } from '@fuse/fuse-colors'
import utils from '../../../../util';

const {
  isNil,
} = require('lodash')

const TickerContainer = styled.div.attrs({
  style: ({ left }) => ({
    left: `${left}px`
  })
})`
  position: absolute;
  background: #353a46;
  height: 44px;
  border-radius: 4px;
  width: 200px;
  color: white;
  padding: 5px 10px;
  top: 8px;
  cursor: pointer;
  font-size: 13px;

  &:hover {
    background: #727888;
  }
`;

export default class Ticker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seconds: 0
    };
  }
  getChangeColor = (change) => {
    if (change < 0) {
      return 'red';
    } else if (change > 0) {
      return fuseDark.colGreen;
    }
    return 'white';
  };

  getChangeIcon = (change, color) => {
    if (change > 0) {
      return (<span className={'material-icons'} style={{ color: color }}>arrow_drop_up</span>);
    } else if (change < 0) {
      return <span className={'material-icons'} style={{ color: color }}>arrow_drop_down</span>;
    }

    return '';
  };

  render() {
    var { left, value, name, price, open, currency } = this.props;
    price = isNil(price) ? 0.0 : price
    open = isNil(open) ? 0.0 : open
    const change = price - open
    let changePercent = '';
    if (change === 0.0) {
      changePercent = 0;
    } else {
      changePercent = ((change / open) * 100);
    }

    let csym = utils.getCurrency(currency);

    const sign = change > 0 ? '+' : ''
    const color = this.getChangeColor(change);
    const icon = this.getChangeIcon(change, color);

    return (
      <TickerContainer left={left} onClick={this.props.onClick}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <div className={'text-bold w-1/2'} style={{fontWeight: 'bolder'}}>{name}</div>
          <div className={'text-bold w-1/2 text-right'} style={{ color: color }}>{csym.currency_symbol} {utils.addThousandCommas(price)}</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-evenly' }}>
          <div className={'w-1/3'}>
            <strong>{utils.reduceByThousand(value)}</strong>&nbsp;
					</div>
          <div className={'w-2/3 text-right'} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
            <div>
              {icon}
            </div>
            <div>
              <strong style={{ color: color }}>{sign}{utils.addThousandCommas(change)}</strong>&nbsp;
							<strong style={{ color: color, fontSize: 9 }}>({changePercent.toFixed(2)}%)</strong>
            </div>
          </div>
        </div>
      </TickerContainer>
    )
  }
}
