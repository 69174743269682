import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, Button, IconButton, Grow, Paper, ClickAwayListener, Popper, MenuItem, MenuList, Avatar } from '@material-ui/core';
import { withRouter } from 'react-router-dom'
import {API, API_URL} from "../../../config";
import { fuseDark } from '@fuse/fuse-colors';
import classNames from 'classnames';
import Settings from '@material-ui/icons/Settings'
import Menu from '@material-ui/core/Menu';
import Ticker from './components/Ticker';
import {fetchCompaniesInfo, fetchCompanyHistory} from '../../../store/actions/company';
import { logOut } from '../../../store/actions/user';
import {ENABLED_LANGUAGES} from "../../../config";
//import utils from '../../../util';

import { translate } from "react-i18next";

const investorsUploadedFiles = API.service('investors-uploaded-files');
const investor = API.service('investors');

const axios = require('axios');
const moment = require('moment-timezone');
const momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

const styles = theme => ({
  root: {
    height: 60,
    minHeight: 60,
    width: '100%',
    background: '#2c303a',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,

    '@media (max-width: 1439px)': {
      width: '100%'
    }
  },
  tickerContainer: {
    width: 'calc(100% - 400px)',
    position: 'relative',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  marketClosed: {
    background: '#ca473f',
    position: 'absolute',
    right: 0,
    top: 0,
    height: 60,
    width: 200,
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
  },
  marketOpen: {
    background: fuseDark.colGreen,
    position: 'absolute',
    right: 0,
    top: 0,
    height: 60,
    width: 200,
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
  },
  localTime: {
    background: fuseDark.colGreen,
    position: 'absolute',
    left: 0,
    top: 0,
    height: 60,
    width: 160,
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
  },
  localTimeClosed: {
    background: '#ca473f',
    position: 'absolute',
    left: 0,
    top: 0,
    height: 60,
    width: 160,
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  profileBar: {
    width: '400px',
    background: '#2c303a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0px 10px 0px',
    zIndex: 1,
    fontSize: 11,
    textTransform: 'capitalize',

    '@media (max-width: 1439px)': {
      margin: 0,
      width: '85px'
    },
    '@media (max-width: 899px)': {
      width: '100%'
    }
  },
  avatar: {
    width: 40,
    height: 40,
    padding: '8px 16px',
    boxSizing: 'content-box',
    '& > img': {
      borderRadius: '50%'
    },
  },
  item: {
    padding: '12px 5px',
    margin: theme.spacing.unit,    
  },
  itmeText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    color: 'white',
  },
  menuIcon: {
    padding: '12px 2px',
    width: '100%'
  },
  menuItem: {
    display: 'flex', 
    width: '100%'
  },
  menuText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    color: 'white',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

class Topbar extends Component {
  constructor(props){
    super(props)
    this.state = {
      left: 200,
      time: moment().tz('America/Curacao'),
      marketState: false,
      marketChange: false,
      isMobileMode: false,
      isPadVersion: false,
      open: false,
      photograph:'assets/images/avatars/profile.jpg',
      anchorEl: null,
      langs: [
        ["en","English"],
        ["zh_CN","中文"],
        ["nl","Nederlands"]
      ],
      selectable: [],
      selected:this.props.lng
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, anchorEl2: null });
  };

  handleMenu2 = event => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleMenuClose2 = () => {
    this.setState({ anchorEl: null, anchorEl2: null });
  };

  componentDidMount() {
    const app = this;
    this.props.getCompaniesInfo();
    this.startScrolling();
    this.updateOpenStatus();

    this.tickerUpdates = setInterval(function () {
      app.updateOpenStatus()
    }, 15000);
    this.secondUpdate = setInterval(function() {
      app.countSecond()
    }, 1000);

    this.setState({
      selectable: ENABLED_LANGUAGES
    })

    investorsUploadedFiles.find({query:{"_account": this.props.userId, "_fieldname":"_avatar", $limit:1,$sort: {_id: -1}}}).then(identification=>{
      if(identification.total > 0){
        /*var d = Date.parse(identification.data[0]._creationdate);
        var m = moment(d);
        var extension = (/[.]/.exec(identification.data[0]._orig_filename)) ? /[^.]+$/.exec(identification.data[0]._orig_filename) : undefined;*/
        this.setState({photograph: identification.data[0]._orig_filename})
      }
    })

    this.screenSize = setInterval(() => this.getScreenMode(), 5000);
  }

  getScreenMode = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ isMobileMode: window.innerWidth <= 899 });
    this.setState({ isPadVersion: window.innerWidth >= 900 && window.innerWidth <= 1439})
  }

  updateOpenStatus = () => {
    axios.get(API_URL + '/clock').then(c => {
      const changes = (c.data.state === 'OPEN') ? c.data.closes_at : c.data.opens_at

      this.setState({
        marketState: c.data.state,
        change: changes
      })
      this.setState({time: moment().tz('America/Curacao')});
    })
  };
  countSecond = () => {
    const now = moment().utc()
    const marketChange = moment.duration(-now.diff(this.state.change), "milliseconds").format("d[d] h[h] m[m] s[s]");
    this.setState({
      counter: marketChange
    })
  }

  handleToggle(event) {
    this.setState(state => ({open: !state.open}));
  }

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)){
      return;
    }
    this.setState({open: false});
  }

  startScrolling = () => {
    this.interval = setInterval(() => {
      const { companies } = this.props;

      let width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

      if (companies !== undefined && this.state.left < (-190 * (companies.length - 1))) {
        this.setState({ left: (width - 400) });
        return;
      }

      this.setState({ left: this.state.left - 1 })
    }, 15);
  };

  selectLanguage = (lng) => {
    let newlang = lng;
    this.setState(prevState => ({ value: newlang }));
    this.setState({
      selected:newlang
    })

    //update client preferences
    investor.patch(this.props.userIdent, {_language:(newlang === 'zh_CN') ? 'cn' : newlang}, {query:{$limit:1}}).then(x=>{
      console.log('Language Preference Changed to: ' + newlang);
    })

    this.props.i18n.changeLanguage(newlang);
    this.handleMenuClose()
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.screenSize);
  }

  onClickTicker(id) {
    this.props.selectCompany(id);
  }

  render() {
    const { classes, companies, isLoading, t } = this.props;
    const { left, isMobileMode, open, isPadVersion, anchorEl, anchorEl2 } = this.state;
    const mOpen = Boolean(anchorEl);
    const mOpen2 = Boolean(anchorEl2);

    return (
      this.props.investor !== undefined && this.props.investor && <div className={classes.root}>
        <div
          className={classes.tickerContainer}
          onMouseEnter={() => clearInterval(this.interval)}
          onMouseLeave={this.startScrolling}
          style={{width: '100%'}}
        >
          {
            !isLoading && !isMobileMode && companies !== undefined && companies.map((company, id) =>
              <Ticker
                key={id}
                left={left + 210 * id}
                name={company.symbol}
                value={company.volume}
                price={company.share_price}
                open={company.open}
                currency={company.currency}
                onClick={() => { this.props.history.push('/trade') || this.props.selectCompany(company.id) }}
              />
            )
          }
          { 
            <div className={(this.state.marketState === "OPEN") ? classes.localTime : classes.localTimeClosed}>
              <div>
                <span>{t('ticker_local_time')}</span><br />
                <span>{this.state.time.format('h:mma zz')}</span>
              </div>
            </div>
          }
          {
            this.state.marketState &&
            <div className={(this.state.marketState === "OPEN") ? classes.marketOpen : classes.marketClosed}>
              <div>
                <span>{(this.state.marketState === 'OPEN') ? t('ticker_market_open') : t('ticker_market_closed') }</span><br />
                <span>{(this.state.marketState === 'OPEN') ? t('ticker_market_closing') : t('ticker_market_opening')} {this.state.counter}</span>
              </div>
            </div>
          }
        </div>
        <div className={classes.profileBar}>
        { isPadVersion && 
          <div className='Hamburger'>
            <Button
              buttonRef={node => {
                this.anchorEl = node;
              }}      
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup='true'
              onClick={this.handleToggle}
            >
              <i 
                className='fa fa-bars' 
                style={{fontSize: '32px', color:'#0071a5'}}
              >
              </i> 
            </Button>
            <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
              {({ TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  id='menu-list-grow'
                  style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                >
                  <Paper style={{zIndex: 1}}>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        <MenuItem >
                          <Avatar
                            className={classNames(classes.avatar, "avatar")}
                            alt="user photo"
                            src={this.state.photograph + "?size=200x200"}
                          />
                          <div style={{color: 'white' }}>
                            <strong style={{ fontWeight: 'bold' }}>{(typeof this.props.investor === 'undefined') ? "" : this.props.investor._firstname} {(typeof this.props.investor === 'undefined') ? "" : this.props.investor._surname}</strong>
                            <span>{(typeof this.props.investor === 'undefined') ? "" : t(this.props.investor.kyctext)}</span>
                            <span className={classes.itmeText}>{t('ticker_user_id_label')}: {(typeof this.props.investor === 'undefined') ? "" : this.props.investor._account}</span>
                          </div>
                        </MenuItem>
                        <MenuItem onClick={this.handleClose}>
                          <IconButton className={classes.menuIcon}>
                            <div className={classes.menuItem} onClick={() => this.props.history.push('/profile')}>
                              <div style={{padding: '8px 16px'}}><span className={'material-icons'} style={{ color: 'white', fontSize:'40px'}}>settings</span></div>
                              <div className={classes.menuText}>{t('ticker_profile_label')}</div>
                            </div>
                          </IconButton>
                        </MenuItem>
                        <MenuItem onClick={this.handleClose}>
                          <IconButton className={classes.menuIcon} onClick={() => this.props.logOut()}>
                            <div className={classes.menuItem}>
                              <div style={{padding: '8px 16px'}}><span className={'material-icons'} style={{ color: 'white', fontSize:'40px'}}>keyboard_tab</span></div>
                              <div className={classes.menuText}>{t('ticker_logout_label')}</div>
                            </div>
                          </IconButton>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        }
        { !isPadVersion &&
          <div className='Profile' style={{display: 'inherit'}}>
            <div className={classes.flexBox}>
              <Avatar
                className={classNames(classes.avatar, "avatar")}
                alt="user photo"
                src={this.state.photograph}
              />
              <div style={{color: 'white' }}>
                <strong style={{ fontWeight: 'bold' }}>{(typeof this.props.investor === 'undefined') ? "" : this.props.investor._firstname} {(typeof this.props.investor === 'undefined') ? "" : this.props.investor._surname}</strong>&nbsp;&nbsp;&nbsp;
                <span className={classes.itmeText}>{t('ticker_user_id_label')}: {(typeof this.props.investor === 'undefined') ? "" : this.props.investor._account}</span>
                <span>{(typeof this.props.investor === 'undefined') ? "" : t(this.props.investor.kyctext)}</span>
              </div>
            </div>
            <div className={classes.flexBox}>
              <IconButton className={classes.item} >
                <div className={classes.flexBox} style={{ flexDirection: 'column' }} > {/*onClick={() => this.props.history.push('/profile')}>*/}
                  {/*<div><span className={'material-icons'} style={{ color: 'white' }}>settings</span></div>*/}

                  <div style={{position:'relative'}}>
                    <IconButton
                      aria-owns={mOpen ? 'menu-appbar' : null}
                      aria-haspopup="true"
                      onClick={this.handleMenu}
                      color="inherit"
                    >
                      <Settings />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: {
                          width: '120px'
                        },
                      }}
                      open={mOpen}
                      onClose={this.handleMenuClose }
                    >
                      <MenuItem onClick={() => this.props.history.push('/profile')}>{t('ticker_profile_label')}</MenuItem>
                      <MenuItem onClick={this.handleMenu2}>{t('language_selector_label')}</MenuItem>
                    </Menu>

                    <Menu
                      id="menu-appbar2"
                      anchorEl={anchorEl2}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: {
                          maxHeight: 216,
                          width:'120px'
                        },
                      }}
                      style={{position:'absolute',left:-100}}
                      open={mOpen2}
                      onClose={this.handleMenuClose2 }
                    >
                      {this.state.langs.map((i) => {
                        if(this.state.selectable.includes(i[0])){
                          return (
                            <MenuItem key={i[0]} onClick={() => this.selectLanguage(i[0])} >{i[1]}</MenuItem>
                          )
                        } else {
                          return false;
                        }
                      })}
                    </Menu>
                  </div>

                  <div className={classes.itmeText} style={{top:'-8px', position:'relative'}}>{t('ticker_settings_label')}</div>
                </div>
              </IconButton>
              <IconButton className={classes.item} onClick={() => this.props.logOut()}>
                <div className={classes.flexBox} style={{ flexDirection: 'column' }}>
                  <div><span className={'material-icons'} style={{ color: 'white' }}>keyboard_tab</span></div>
                  <div className={classes.itmeText}>{t('ticker_logout_label')}</div>
                </div>
              </IconButton>
            </div>
          </div>
        }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  companies: state.company.companies,
  isLoading: state.company.isLoading,
  userId: state.user.userInfo._account,
  userIdent: state.user.userInfo._id,
});

const mapDispatchToProps = {
  selectCompany: fetchCompanyHistory,
  getCompaniesInfo: fetchCompaniesInfo,
  logOut,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(withRouter(translate('translations')(Topbar))));
