import React from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import {BROKER_LOGO, BROKER_NAME, BROKER_LOGO_SYMBOL, BROKER_LOGO_WIDTH} from "../config";

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .logo-text, & .react-badge': {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    }
  },
  logo: {},
  logoIcon: {
    width: 48,
    height:'auto',
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    }),
    display:'block !important'
  },
  reactBadge: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: '#61dafb'
  }
});

function MainNavbarHeader({ classes }) {
  return (
    <div className={classes.root}>
      <div className={classNames(classes.logo, "flex items-center")}>
        {(BROKER_LOGO_SYMBOL === 'false') &&
          <React.Fragment>
            <img className={classNames(classes.logoIcon, "logo-icon")} src={'/'+BROKER_LOGO} alt="logo" />
            <Typography className="text-16 ml-16 font-light logo-text" color="inherit" style={{ padding: '0 16px' }}>{BROKER_NAME}</Typography>
          </React.Fragment>
        }

        {(BROKER_LOGO_SYMBOL !== 'false') &&
          <React.Fragment>
            <img className={classNames(classes.logoIcon, "logo-icon")} src={BROKER_LOGO_SYMBOL} alt="logo" width={BROKER_LOGO_WIDTH}/>
            <Typography className="text-16 ml-16 font-light logo-text" color="inherit" style={{ padding: '0 16px' }}>{BROKER_NAME}</Typography>
          </React.Fragment>
        }
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(withRouter(MainNavbarHeader));
