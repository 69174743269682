import { FuseLoadable } from '@fuse';

export const ForgotPasswordConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        scroll: 'content',
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    },
    theme: {
      main: 'defaultDark',
      navbar: 'mainThemeDark',
      toolbar: 'mainThemeLight',
      footer: 'mainThemeDark'
    }
  },
  routes: [
    {
      path: '/forgotpassword',
      component: FuseLoadable({
        loader: () => import('./ForgotPassword')
      })
    },
    {
      path: '/resetpassword/:hash',
      component: FuseLoadable({
        loader: () => import('./ResetPassword')
      })
    }
  ]
};
