import { FuseLoadable } from '@fuse';
import Authorize from '../../Authorize';

export const currentIPOConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/currentipo/:company_account?',
      component: Authorize(FuseLoadable({
        loader: () => import('./CurrentIPO')
      }))
    },
    {
      path: '/selectedipo/:company_account?',
      component: Authorize(FuseLoadable({
        loader: () => import('./CurrentIPO')
      }))
    },
    {
      path: '/ipos',
      component: Authorize(FuseLoadable({
        loader: () => import('./IPOS')
      }))
    }
  ]
};
