import "@babel/polyfill";
import 'typeface-muli';
import React from 'react';
import ReactDOM from 'react-dom';
import './react-table-defaults';
import './styles/index.css';
//import registerServiceWorker from './registerServiceWorker';
import App from './App';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n'

ReactDOM.render(<I18nextProvider i18n={i18n}><App /></I18nextProvider>, document.getElementById('root'));

//registerServiceWorker();

/*console.warn = function(){
  console.log('hiding warn')
};

console.error = function(){
  console.log('hiding error')
};

console.info = function(){
  console.log('hiding info')
};*/