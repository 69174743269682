import { FuseLoadable } from '@fuse';
import Authorize from '../../Authorize';

export const financialConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/financial/:current_tab',
      component: Authorize(FuseLoadable({
        loader: () => import('./Financial')
      }))
    },
    {
      path: '/financial',
      component: Authorize(FuseLoadable({
        loader: () => import('./Financial')
      }))
    }
  ]
};
