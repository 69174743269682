import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {BROKER_LOGO, BROKER_NAME, BROKER_SHORT, BROKER_POSITION} from "../../../config";

const styles = theme => ({
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDesktopMode: true,
      footer: 'Member of the DCSX (Dutch Caribbean Securities Exchange)',
    }
  }
  getScreenMode = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ isDesktopMode: window.innerWidth >= 1440 });
  }

  componentDidMount() {
    this.interval = setInterval(() => this.getScreenMode(), 5000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { classes } = this.props;
    const {isDesktopMode} = this.state;
    const footer = isDesktopMode ? BROKER_POSITION + ' of the DCSX (Dutch Caribbean Securities Exchange)' : BROKER_POSITION + ' of the DCSX';
    const imgSrc = isDesktopMode ? 'assets/images/logos/company_logo.png' : 'assets/images/logos/company_logo_1.png';
    return (
      <div style={{ width: '100%' }}>
        <div className="row">
          <div className={classNames("col-md-12", classes.flexBox)} >
            <div style={{display: 'flex', alignItems: 'center' }}>
              <img src={'\\'+BROKER_LOGO} alt={BROKER_SHORT + " Logo"} width={'auto'} height={"20px"} style={{paddingRight: '20px'}}/><span>Copyright &copy; 2019 {BROKER_NAME}. All Rights Reserved</span>
            </div>
            <div style={{ paddingRight: '20px', display: 'flex', alignItems: 'center' }}>
              <span style={{ padding: '15px', whiteSpace: 'nowrap'}}>{footer}</span>
              <img src={'\\'+imgSrc} alt={"Company Logo"} width={'auto'} height={"30px"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Footer);
