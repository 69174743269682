export const fuseNavigationConfig = [
  {
    'id': 'main',
    'type': 'group',
    'icon': 'apps',
    'children': [
      {
        'id': 'trade',
        'title': 'navigation_trading',
        'type': 'item',
        'image': 'trade.png',
        'url': '/trade',
      },
      {
        'id': 'currentipo',
        'title': 'navigation_broker_featured',
        'type': 'item',
        'icon': 'event',
        'url': '/currentipo',
      },
      {
        'id': 'ipos',
        'title': 'navigation_current_offerings',
        'type': 'item',
        'icon': 'event',
        'url': '/ipos',
      },
      {
        'id': 'profile',
        'title': 'navigation_profile',
        'type': 'item',
        'image': 'profile.png',
        'url': '/profile',
      },
      {
        'id': 'financial',
        'title': 'navigation_account',
        'type': 'item',
        'image': 'financial.png',
        'url': '/financial',
      },
    ],
  }
];
