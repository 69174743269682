import {COLOR_SCHEME} from "../config";

const fuseSettingsConfig = {
  layout: {
    style: 'layout1',
    config: {
      scroll: 'content',
      navbar: {
        display: true,
        folded: true,
        position: 'left'
      },
      toolbar: {
        display: true,
        style: 'fixed',
        position: 'below'
      },
      footer: {
        display: true,
        style: 'fixed',
        position: 'below'
      },
      leftSidePanel: {
        display: true,
      },
      rightSidePanel: {
        display: true,
      },
      mode: 'fullwidth'
    }
  },
  customScrollbars: true,
  theme: {
    main: (COLOR_SCHEME === 'light') ? 'default' : 'defaultDark',
    navbar: (COLOR_SCHEME === 'light') ? 'default' : 'defaultDark',
    toolbar: 'mainThemeLight',
    footer: 'mainThemeDark'
  }
};

export default fuseSettingsConfig;