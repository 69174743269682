import {
  SET_USER_INFO,
  LOG_OUT,
  SET_INBOX_ITEM_COUNT,
} from '../actions/user';

const defaultState = {
  userInfo: {},
  inboxItems: 0,
  authenticated: false,
};

const reducer = (state = defaultState, action) => {

  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, userInfo: action.payload, authenticated: true };
    case LOG_OUT:
      return { ...state, userInfo: {}, authenticated: false };
    case SET_INBOX_ITEM_COUNT:
      return { ...state, inboxItems: action.payload > 0 ? action.payload : 0};
    default:
      return state;
  }
}

export default reducer;
