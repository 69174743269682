import {
  SET_BUSY,
  SET_HISTORY_LOADING,
  SET_COMPANIES_INFO,
  SELECT_COMPANY,
  SET_COMPANY_HISTORY,
  SET_QUERY,
  ADD_REMOVE_WATCH_LIST,
  SET_WATCHLIST,
  ADD_TRADE,
} from '../actions/company';

const defaultState = {
  isLoading: false,
  isTrade: false,
  companies: [],
  selectedCompany: 0,
  isHistoryLoading: false,
  history: [],
  query: '',
  watchList: [],
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_BUSY:
      return { ...state, isLoading: true };

    case SET_COMPANIES_INFO:
      return { ...state, companies: action.info, isLoading: false };

    case SET_HISTORY_LOADING:
      return { ...state, isHistoryLoading: true };

    case SELECT_COMPANY:
      return { ...state, selectedCompany: action.id };

    case SET_COMPANY_HISTORY:
      return { ...state, selectedCompany: action.id, history: action.history, isHistoryLoading: false };

    case SET_QUERY:
      return { ...state, query: action.query };

    case ADD_TRADE:
      if (action.atype === 'add')
        return { ...state, isTrade: true }
      else
        return { ...state, isTrade: false }
    case ADD_REMOVE_WATCH_LIST:
      const ftype = action.ftype;
      console.log("reducerevent:", ftype);
      const payload = action.payload;
      const id = payload._account_to_follow;
      let list = state.watchList;
      if (!list.length) {
        return { ...state, watchList: [id] };
      } else if (list.indexOf(id) === -1) {
        return { ...state, watchList: [...list, id] };
      } else {
        return { ...state, watchList: list.filter(i => i !== id) };
      }
    case SET_WATCHLIST:
      const followed = action.followed;
      return { ...state, watchList: followed };
    default:
      return state;
  }
};

export default reducer;