import React, { Component } from 'react';
//import { PersistGate } from 'redux-persist/lib/integration/react';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { routes } from './fuse-configs/fuseRoutesConfig';
import { FuseLayout, FuseTheme, FuseAuthorization } from '@fuse';
import MainNavbarContent from './main/MainNavbarContent';
import MainNavbarHeader from './main/MainNavbarHeader';
import MainToolbar from './main/content/layout/Topbar';
import Footer from './main/content/layout/Footer';
import jssExtend from 'jss-extend';
import store, { history} from 'store';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()]
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();

class App extends Component {
  render() {
    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>

            <Router history={history}>
              <FuseAuthorization routes={routes}>
                <FuseTheme>
                  <FuseLayout
                    routes={routes}
                    toolbar={
                      <MainToolbar />
                    }
                    navbarHeader={
                      <MainNavbarHeader />
                    }
                    navbarContent={
                      <MainNavbarContent />
                    }
                    footer={
                      <Footer />
                    }
                  >
                  </FuseLayout>
                </FuseTheme>
              </FuseAuthorization>
            </Router>

        </Provider>
      </JssProvider>
    )
  }
}

export default App;